import React from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import { FiberManualRecord } from "@mui/icons-material";
import  CheckCircleOutlineIcon  from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import api from "../../../api";
import swal from "sweetalert";

interface ValidatePaperAnnotationsButtonProps {
  currentRecord: any;
  useWhiteOutclinedButton: boolean;
}


export function ValidatePaperAnnotationsButton({
  currentRecord,
  useWhiteOutclinedButton,
}: ValidatePaperAnnotationsButtonProps) {
  const [open, setOpen] = React.useState(false);
  const [processingValidation, setProcessingValidation] = React.useState(false);
  const [validationIcon, setValidatationIcon] = React.useState(false);
  const [validationTexts, setValidationTexts] = React.useState([]);
  const validateIntervalTime = 60000;

  let validationInterval: NodeJS.Timeout | number;

  const onValidateVariables = (e: any) => {
    setProcessingValidation(true);
    api
      .validatePaperAnnotations(currentRecord.id)
      .then((response: any) => {
        setProcessingValidation(false);
        if (response.data.length === 0) {
          let msg = "Validation result: no warnings found";
          swal("Validation result", msg, "success", {
            closeOnClickOutside: false,
          }).then((ok) => {
            setValidatationIcon(true);
            clearInterval(validationInterval);    
          });
        } else {
          setValidationTexts(response.data);
          setOpen(true);
        }
      })
      .catch((e) => {
        console.log("Validate Paper error: " + e);
        setProcessingValidation(false);
      });
  };

  const autoValidateVariables = (e: any) => {
    api.validatePaperAnnotations(currentRecord.id)
      .then((response: any) => {   
        if (response.data.length === 0) {
            setValidatationIcon(true);
            clearInterval(validationInterval);    
        } else {
          setValidatationIcon(false);
        }
      })
      .catch((e) => {
        console.log("Validate Paper error: " + e);
      });
  }

  validationInterval = setInterval(autoValidateVariables, validateIntervalTime);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {useWhiteOutclinedButton ? (
        <Button
          size="medium"
          variant="outlined"
          style={{ borderColor: "white", color: "white", marginRight: 20 }}
          onClick={onValidateVariables}
        >
          {processingValidation ? (
            <CircularProgress
              size="1.2rem"
              style={{
                borderColor: "white",
                color: "white",
                marginRight: "0.4rem",
              }}
            />
          ) : null}
          {validationIcon ? (<CheckCircleOutlineIcon style={{marginRight: "3px"}} /> ) : (<ErrorOutlineIcon style={{marginRight: "3px"}} /> )}
          Validate Variables
        </Button>
      ) : (
        <Button
          component="span"
          variant="contained"
          disableRipple
          color="secondary"
          size="medium"
          onClick={onValidateVariables}
        >
          {processingValidation ? (
            <CircularProgress
              size="1.2rem"
              style={{
                marginRight: "0.4rem",
              }}
            />
          ) : null}
          <Typography variant="body2" style={{
                display: "inline-flex",
                alignItems: "center"
              }}>
              {validationIcon ? (<CheckCircleOutlineIcon style={{marginRight: "3px"}} /> ) : (<ErrorOutlineIcon style={{marginRight: "3px"}} /> )}
              Validate Variables
         </Typography>
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography>Validation Results</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The validation gave the following results
          </DialogContentText>
          {validationTexts !== null ? (
            <List>
              {validationTexts.map((validationText: string) => {
                return (
                  <ListItem>
                    <FiberManualRecord
                      style={{ fontSize: 10, marginRight: "5px" }}
                    />
                    <Typography>{validationText}</Typography>
                  </ListItem>
                );
              })}
            </List>
          ) : null}
        </DialogContent>
        <DialogActions>
        <Button
          component="span"
          variant="contained"
          disableRipple
          color="secondary"
          size="medium"
          onClick={onValidateVariables}
        >
          {processingValidation ? (
            <CircularProgress
              size="1.2rem"
              style={{
                marginRight: "0.4rem",
              }}
            />
          ) : null}
          <Typography variant="body2" display="block" align="center">
               Re-validate
         </Typography>
        </Button>
          <Button
            variant="contained"
            onClick={() => handleClose()}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
